<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit Task
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create Task
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Framework Item</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                v-model="selectedFrameworkItemIDSelected"
                :options="frameworkItems"
            ></b-form-select>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Reference</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="reference_id"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.taskRecord.reference_id.$model"
            />
            <div class="error" v-if="!$v.taskRecord.reference_id.required">
              <i>Task Reference is required</i>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Task</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.taskRecord.name.$model"
            />
            <div class="error" v-if="!$v.taskRecord.name.required">
              <i>Task Name is required</i>
            </div>
          </div>
        </div>

        

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Description</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="description"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.description"
            />
            <div class="error" v-if="!$v.taskRecord.description.required">
              <i>Task Description is required</i>
            </div>            
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Priority</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="priority"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.priority"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Assigned to name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="assign_name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.assign_name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Assigned to Email</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="assign_email"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.assign_email"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Date Created</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="created_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.created_date"
            />
            <div class="error" v-if="!$v.taskRecord.created_date.required">
              <i>Task Created date is required</i>
            </div>    
          </div>       
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Date Due</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="due_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.due_date"
            />
            <div class="error" v-if="!$v.taskRecord.due_date.required">
              <i>Task Due date is required</i>
            </div> 
          </div>
         
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Expiry date</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="expiry_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.expiry_date"
            />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Date Completed</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="completed_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="taskRecord.completed_date"
            />
          </div>
        </div>        

   


      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_FRAMEWORKTASK_DETAILS,
  CREATE_FRAMEWORKTASK_DETAILS,
}  from "@/core/services/store/frameworktasks.module";


import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
    selectedFrameworkItemID: String,
    frameworkItems:Array,    
  },
  data() {
    return {
      taskRecord: this.record,
      selectedFrameworkItemIDSelected: this.selectedFrameworkItemID      
    };
  },
  validations: {
    taskRecord: {
      reference_id: {
        required,
      },
      name: {
        required,
      },
      description: {
        required,
      },
      due_date: {
        required,
      },
      created_date: {
        required,
      }
    },
  },
  mounted() {
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {

        var framework_requirement_response_id= this.selectedFrameworkItemIDSelected;        
        var reference_id = this.$refs.reference_id.value;
        var name = this.$refs.name.value;
        var description = this.$refs.description.value;
        var priority = this.$refs.priority.value;
        var assign_name = this.$refs.assign_name.value;
        var assign_email = this.$refs.assign_email.value;
        var created_date = this.$refs.created_date.value;
        var due_date = this.$refs.due_date.value;
        var expiry_date = this.$refs.expiry_date.value;
        var completed_date = this.$refs.completed_date.value;
        
        var id = this.taskRecord.id;
        var framework_id = this.selectedFramework;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_FRAMEWORKTASK_DETAILS, {
              id,
              reference_id,
              name,
              description,
              priority,
              assign_name,
              assign_email,
              created_date,
              due_date,
              expiry_date,
              completed_date,
              framework_id,
              framework_requirement_response_id              
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request

          this.$store
            .dispatch(UPDATE_FRAMEWORKTASK_DETAILS, {
              id,
              reference_id,
              name,
              description,
              priority,
              assign_name,
              assign_email,
              created_date,
              due_date,
              expiry_date,
              completed_date,
              framework_id,
              framework_requirement_response_id              

            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    ...mapGetters(["currentUser","selectedFramework"]),
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
