<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <b-row v-if="!editFRAMEWORKTASK">
          <b-col>
            <button
              type="reset"
              class="btn btn-success mr-2"
              @click="create()"
              ref="kt_save_changes"
            >
              Add New TASK
            </button>
          </b-col>
          <b-col>
            <b-form-select
                v-model="selectedFrameworkItemID"
                :options="frameworkItems"
            ></b-form-select>
          </b-col>
          <b-col cols="2">
            <b-button-group style="float:right">
              <b-button :variant="[showBy=='list'? 'primary' : 'outline-secondary']" v-on:click="setTabClick('list')">By List</b-button>
              <b-button :variant="[showBy=='date'? 'primary' : 'outline-secondary']" v-on:click="setTabClick('date')">By Date</b-button>
            </b-button-group>            
          </b-col>          
        </b-row>
      </div>      

      <div v-if="!editFRAMEWORKTASK && showBy=='list'">
        <b-table
          striped
          hover
          sort-icon-left        
          :items="currentFRAMEWORKACTIONsDataItems"
          :fields="frameworktaskFields"
          @row-clicked="rowClickHandler"
        >
          <template #cell(framework_requirement_response_id)="data">
            {{getFrameworkLinkedTo(data.item)}}
          </template>        
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id)"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
      </div>
      <div v-if="!editFRAMEWORKTASK && showBy=='date'" >
        <div style="padding-top:20px;">
          <full-calendar 
            :events="getEvents"
            :config="calenadrConfig"
            ref="calendar"
            @event-selected="eventSelected"
          ></full-calendar>
        </div>
        
      </div>


      <div v-if="editFRAMEWORKTASK">
        <ActionsInformation
          :record="editFRAMEWORKTASKRecord"
          :frameworkItems="frameworkItems"
          :selectedFrameworkItemID="selectedFrameworkItemID"          
          @canceled="editFRAMEWORKTASK = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_FRAMEWORKTASKS,
  DELETE_FRAMEWORKTASK_DETAILS,
} from "@/core/services/store/frameworktasks.module";

import { GET_FRAMEWORK } from "@/core/services/store/framework.module";
import { GET_USERS } from "@/core/services/store/users.module";


import ActionsInformation from "@/view/pages/work/tasks/TasksInformation";

export default {
  name: "manageframeworktasks",
  components: {
    ActionsInformation,
    FullCalendar
  },
  data() {
    return {
      selectedFrameworkID:'',
      selectedFrameworkItemID:'',      
      editFRAMEWORKTASK: false,
      editFRAMEWORKTASKRecord: [],
      showBy:'date',
      events:[],
      calenadrConfig: {
        defaultView:"month",
        header:{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth',              
        },
        buttonText : {
          prev : '<',
          next : '>'
        }
    
      },


      frameworktaskFields: [
        { key: "reference_id", label: "Reference", sortable: true },
        { key: "framework_requirement_response_id", label: "Linked To" },        
        { key: "name" , label: "Task"},
        { key: "priority" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage Tasks" }]);


    if (this.$route.params.id) {
      this.selectedFrameworkItemID = this.$route.params.id;
    }

    if (this.$route.params.frameworkid) {
      this.selectedFrameworkID = this.$route.params.frameworkid;
    }

    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_FRAMEWORKTASKS, {framework_id: this.selectedFramework});

      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      this.$store.dispatch(GET_FRAMEWORK,frameworkID);
      this.$store.dispatch(GET_USERS);       
    },
    setTabClick(type) {
      this.showBy = type
      this.$nextTick(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.$emit('reload-events')
        }
      });

    },
    eventSelected(args) {
      console.log(args);
      this.rowClickHandler(args.record,1);
    },    
    getFrameworkLinkedTo(item) {
      var retVal = 'n/a';
      if (item.framework_requirement_response_id == null) { 
        retVal = "All";
      } else {
        if (this.currentFramework.objective_items) { 
          this.currentFramework.objective_items.forEach(o => {
            if (o.id == item.framework_requirement_response_id) {
            console.log('------',o,item.framework_requirement_response_id);
                retVal = o.objective_code + ' - ' + o.objective + ' - ' + o.questions;
            }
            
          });
        }
      }        
      
      return retVal;
    },  
    finished() {
      this.editFRAMEWORKTASK = false;
      this.editFRAMEWORKTASKRecord = [];
      setTimeout(() => {
        this.getData();
      }, 500);

    },
    rowClickHandler(record, index) {
      console.log(record, index);
      this.selectedFrameworkItemID= record.framework_requirement_response_id;      
      this.editFRAMEWORKTASK = true;
      this.editFRAMEWORKTASKRecord = record;
    },

    create() {
      this.editFRAMEWORKTASK = true;
      var taskReference =  String('00000' + this.currentFRAMEWORKACTIONsDataItems.length).slice(-4);      
      this.editFRAMEWORKTASKRecord = {
        id: -1,
        reference_id: "TS:" + taskReference,
        action: "",
        status: "",
        how: "",
        when_date: "",
        who_id: "",
        validation: "",
        review_date: ""
      };
    },
    deleteRecord(id) {
      console.log("delete");
      if (confirm("Do you really want to delete?")) {
        this.editFRAMEWORKTASK = false;
        this.$store.dispatch(DELETE_FRAMEWORKTASK_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);

      }
    },
  },
  computed: {
    ...mapGetters(["currentFRAMEWORKTASKsData","selectedFramework","currentFramework"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getEvents() {

      var events = [];
  
      if (this.currentFRAMEWORKTASKsData.length > 0) {
          this.currentFRAMEWORKTASKsData.forEach(o => {

              events.push({
                title: o.reference_id + ' - ' + o.name,
                start: o.due_date,
                record: o,
              });
          });
      }

      console.log(events);

      return { events: events }
    },    
    currentFRAMEWORKACTIONsDataItems() {

      var targetListResult = this.currentFRAMEWORKTASKsData;

            console.log('this.selectedFrameworkItemID');
            console.log(this.selectedFrameworkItemID);
  
      if (this.currentFRAMEWORKTASKsData.length > 0) {
        if (this.selectedFrameworkItemID != '') {
          targetListResult = [];
          this.currentFRAMEWORKTASKsData.forEach(o => {
            if (o.framework_requirement_response_id == this.selectedFrameworkItemID) {
              targetListResult.push(o);
            }
            
          });
        }
      }
        return targetListResult;
    },
    frameworkItems() {
      var results = [];
      results.push({
        value: '',
        text: 'All',
      });
      
      if (this.currentFramework.objective_items) {   
        this.currentFramework.objective_items.forEach((item) => {
          console.log(item);
          results.push({
            value: item.id,
            text: item.objective_code + ' - ' + item.objective + ' - ' + item.questions,
          });
        });
      }
      return results;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
<style>
.table-hover tr td {
    cursor: pointer;
}
</style>